import React, { useEffect } from 'react';
import classNames from 'classnames';
import SectionModules from 'containers/Sections/SectionModules';
import Shell from 'components/Shell/Shell';
import {
    Contentful,
    getMasterControl,
    getLandingPages,
} from '@viome/vnxt-nucleus';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ErrorPage from 'pages/404';
import { contentfulOptions as options } from 'utils/constants';

const Page = (props) => {
    const { preview, slug, masterControl, page } = props,
        sections = page?.content?.sections.items || [];

    if (page.error) {
        return <ErrorPage />;
    }

    const isProduct =
        slug === 'products-compare' ||
        slug === 'products' ||
        slug === 'plans' ||
        slug === 'tests';

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        if (isProduct) {
            window.dataLayer.push({
                event: 'productsPage',
            });
        }
    }, []);

    return (
        <Shell
            masterControl={masterControl}
            page={page}
            preview={preview}
            className={classNames(`${slug} vls viome3`)}
        >
            <SectionModules modules={sections} />
        </Shell>
    );
};

export default Page;

export async function getStaticProps({ params, preview = false }) {
    const commonPages = [
            'terms',
            'privacy-policy',
            'cookie-policy',
            'accessibility',
            'ccpa_notice',
        ],
        slugPerflix = commonPages.includes(params.slug) ? '' : 'vls-',
        pageData = Promise.all([
            getMasterControl.get(options),
            Contentful(
                'page',
                {
                    include: 10,
                    'fields.slug[in]': `${slugPerflix}${params.slug}`,
                },
                false,
                options,
            ),
        ]),
        props = await pageData.then(async ([masterControl, page]) => ({
            preview,
            slug: params.slug,
            masterControl,
            page: page ?? null,
        }));

    return { props, revalidate: 60 };
}

export async function getStaticPaths() {
    const excludeSlugs = ['vls-home', 'vls-publications'],
        allLandingPages = await getLandingPages.getLandingPages(
            options,
            [],
            'vls',
        ),
        landingPagesSlugs = Array.isArray(allLandingPages)
            ? allLandingPages
                  .filter(({ seo, content, slug }) => {
                      const noIndex = seo?.noIndex === true,
                          pageType = content?.__typename;

                      return (
                          pageType === 'LandingPageNew' &&
                          !noIndex &&
                          !excludeSlugs.includes(slug)
                      );
                  })
                  .map((page) => {
                      const slug = page?.slug;
                      return `/${slug.replace('vls-', '')}` ?? [];
                  })
            : [],
        paths = landingPagesSlugs;

    return {
        paths,
        fallback: 'blocking',
    };
}
